/* eslint-disable */
import {Vue} from 'vue-class-component';
import { State, Action, Mutation, Getter } from 'vuex-class'
import { CustomerState } from '@/store/customer/types';
import {ValidCredentials} from '@/services/validation.service';
import {FormRegisterData} from '@/components/pages/create-account/create-account.interface';

const namespace: string = 'customer';

export default class CreateAccountPage extends Vue {
	credentials: FormRegisterData = {
		username: '',
		email: '',
		password: '',
	};

	repeatPassword: string = '';
	rememberPassword: boolean = false;
	isAgreement: boolean = false;
	isValid: string = '';

	switchWatchPass: boolean = false;
	switchWatchPassRepeat: boolean = false;
	typeInput: string = 'password';
	typeInputRepeat: string = 'password';
	errorName: string = '';

	@State('customer') customer: CustomerState | undefined;
	@Action('register', { namespace }) register: any;
	@Mutation('setRememberPassword', { namespace }) setRememberPassword: any;
	@Mutation('stateAgreement', { namespace }) stateAgreement: any;
	@Getter('getRequestStatus', {namespace}) getRequestStatus: any;
	@Getter('getMessages', {namespace}) getMessages: any;

	toSwitchWatchPassword(type: string) {
		switch (type){
			case 'pass':
				this.switchWatchPass = !this.switchWatchPass;
				if(!this.switchWatchPass) {
					return this.typeInput = 'password';
				} else {
					return this.typeInput = 'text';
				}

			case 'repeat':
				this.switchWatchPassRepeat = !this.switchWatchPassRepeat;
				if(!this.switchWatchPassRepeat) {
					return this.typeInputRepeat = 'password';
				} else {
					return this.typeInputRepeat = 'text';
				}
			default:
				break;
		}
	}

	onSubmit () {
		if(ValidCredentials(this.credentials, this.repeatPassword) === 'true') {
			this.getRequestStatus ? this.isValid = 'is-valid': '';
			this.register({
				username: this.credentials.username,
				email: this.credentials.email,
				password: this.credentials.password,
				tellUs: '',
				type: 3
			});
			this.setRememberPassword(this.rememberPassword);
			this.stateAgreement(this.isAgreement);
		} else {
			!this.getRequestStatus ? this.isValid = 'is-invalid' : '';
			// console.log(ValidCredentials(this.credentials, this.repeatPassword));
			ValidCredentials(this.credentials, this.repeatPassword) === 'incorrect password' ? this.errorName = 'pass' :
				ValidCredentials(this.credentials, this.repeatPassword) === 'incorrect email' ? this.errorName = 'email' :
					ValidCredentials(this.credentials, this.repeatPassword) === 'incorrect username' ? this.errorName = 'login' :
						ValidCredentials(this.credentials, this.repeatPassword) === 'repeated password wrong' ? this.errorName = 'repeat' : '';
		}
	}
}
