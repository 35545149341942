/* eslint-disable */
import {credentials, result} from './validation.types';

export const ValidPassword = (pass: string) => {
	const reg = /^(\S).{6,}$/;
	return reg.test(pass)
};

export const ValidEmail = (email: string) => {
	const reg = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;
	return reg.test(email.toLowerCase())
};

export const ValidNickName = (nickName: string) => {
	const reg = /^[a-z0-9_-]{3,16}$/;
	return reg.test(nickName.toLowerCase())
};

export const ValidCredentials = (customerCredentials: any, repeatPassword: string) : String | undefined => {
	const result: result = {
		username: false,
		email: false,
		password: false
	};
	result.username = ValidNickName(customerCredentials.username);
	result.email = ValidEmail(customerCredentials.email);
	result.password = ValidPassword(customerCredentials.password);

	if(result.username
		&& result.email
		&& result.password
		&& customerCredentials.password === repeatPassword) {
		return 'true'
	} else if(!result.password) {
		return 'incorrect password'
	} else if(!result.email) {
		return 'incorrect email'
	} else if(!result.username) {
		return 'incorrect username'
	} else if(customerCredentials.password !== repeatPassword) {
		return 'repeated password wrong'
	}

};
